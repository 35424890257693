<template>
  <div
    v-loading="loading"
    class="register-comp"
  >
    <header-comp
      title="Реестр актов"
      create-button-text="Создать акт"
      :export-url="exportUrl"
      :object-ids="objectIds"
      @createHandler="openModal"
    />

    <controls-comp
      :filter-text="filterText"
      :filters="filters"
      @handle-filter-text="filterText = $event"
      @reset-filters="resetFilters"
      @load-filter="updateFilters"
    />
    <div class="register-comp-content">
      <r-table-list
        v-if="tableData.length"
        ref="table"
        bordered
        num-col
        min-width="160"
        :custom-width="customWidth"
        :include-columns="columns"
        :fields-names="tableFields"
        :paginator="tableData.length > 10"
        :data="tableData"
        @click-handler="openModal"
      />
      <r-block
        v-else
        center
      >
        <r-text>
          Нет данных
        </r-text>
      </r-block>
    </div>
  </div>
</template>

<script>
import { filtersEncoder } from '@/utils'
import { filters, columns, tableFields, related, customWidth } from './configs'
import { buildUrl, buildExportUri, parseTableData } from './helpers'

export default {
  components: {
    headerComp: () => import('./components/header'),
    controlsComp: () => import('./components/controls')
  },
  data() {
    return {
      loading: false,
      source_id: this.$store.state.bdd.actsUrl,
      exportUrl: '',
      related: related(),
      filterText: '',
      filters: [],
      actsData: [],
      tableFields,
      columns,
      customWidth
    }
  },
  computed: {
    actObjectFields() {
      return this.$store.state.bdd.actObjectFields || {}
    },
    objectIds() {
      return this.tableData?.map(e => e.id) || []
    },
    tableData() {
      const parsed = parseTableData(
        this.actsData,
        columns,
        this.actObjectFields,
        this.related
      )

      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) return parsed

      return parsed?.filter(p => p.act_no?.toLowerCase()?.includes(query))
    }
  },
  watch: {
    '$store.state.bdd.updateActs'(val) {
      if (!val) return
      this.loadData()
    }
  },
  async created() {
    this.loading = true

    try {
      this.setFilters()
      await this.loadRelated()
      await this.loadData()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.acts
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    async loadRelated() {
      for (const r in this.related) {
        if (this.related[r].id) {
          try {
            const config = JSON.stringify(this.related[r].config)
            const url = `objectInfo/${this.related[r].id}?config=${config}`

            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url
            })
            if (this.related[r]?.key) {
              this.related[r].data = Object.values(data)?.map(d => {
                return {
                  ...d,
                  name: d?.[this.related[r]?.key] || '—'
                }
              })
            } else {
              this.related[r].data = Object.values(data)
            }
          } catch (e) {
            throw new Error(e)
          }
        }
      }
      this.$store.commit('BDD_SET_FIELD', {
        field: 'actRelated',
        value: this.related
      })

      this.filters = this.filters.map(f => {
        let filter = f
        if (f.source) {
          filter = {
            ...f,
            prop: this.related[f.source].data.map(d => {
              const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

              return {
                ...d,
                value: fValue
              }
            })
          }
        }
        return {
          ...filter
        }
      })
    },
    async loadData() {
      try {
        const filters = filtersEncoder(this.filters)
        const url = buildUrl(this.source_id, filters)

        this.exportUrl = buildExportUri(this.source_id, filters)

        const { data: fields } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.source_id}`
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'actObjectFields',
          value: fields
        })
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.actsData = Object.values(data)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateActs',
          value: false
        })
      }
    },
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.acts',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadData()
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.acts',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadData()
    },
    openModal(row) {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'act',
        value: row?.id || null
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-acts-modal')
    }
  }
}
</script>

<style lang="scss">
.register-comp {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: calc(100% - 1rem);
  grid-auto-rows: minmax(50px, 100%);
  grid-template-rows: min-content min-content minmax(50px, 90%);

  &-content {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: stretch;
  }
}
</style>
